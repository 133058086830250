"use client";

import React from "react";
import { StyledBlogHero } from "./blogHero.styles";

export const BlogHero = ({ category }: { category?: string }) => {
  return (
    <StyledBlogHero>
      <div className="hero-wrapper">
        {category && <span>{category}</span>}
        <h1>Common Ninja Blog</h1>
        <h2>
          Discover highly informative and helpful articles on topics such as web
          development, web design, marketing, business & more!
        </h2>
      </div>
    </StyledBlogHero>
  );
};
