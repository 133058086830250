import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledUl = styled.ul`
	display: flex;
	list-style-type: none;
	justify-content: center;
	margin-top: 20px;

	.pagination-item {
		height: 32px;
		text-align: center;
		margin: auto 4px;
		color: ${CSSVars.black};
		display: flex;
		box-sizing: border-box;
		align-items: center;
		letter-spacing: 0.01071em;
		border-radius: 16px;
		line-height: 1.43;
		font-size: 13px;
		min-width: 32px;
		justify-content: center;

		&.dots:hover {
			background-color: transparent;
			cursor: default;
		}

		&:hover {
			background-color: ${CSSVars.gray100};
			cursor: pointer;
		}

		&.selected {
			background-color: ${CSSVars.gray100};
		}

		.arrow {
			&::before {
				position: relative;
				content: '';
				display: inline-block;
				width: 0.4em;
				height: 0.4em;
				border-right: 0.12em solid ${CSSVars.black};
				border-top: 0.12em solid ${CSSVars.black};
			}

			&.left {
				transform: rotate(-135deg) translate(-50%);
			}

			&.right {
				transform: rotate(45deg);
			}
		}

		&.disabled {
			pointer-events: none;

			.arrow::before {
				border-right: 0.12em solid ${CSSVars.gray400};
				border-top: 0.12em solid ${CSSVars.gray400};
			}

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}
`;
