"use client";

import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";
import React, { Suspense, useState } from "react";
import { IBlogPage } from "../../types";
import { NewsLetterForm } from "../newsLetterForm/newsLetterForm";
import { StyledDiv } from "./blogPosts.styles";
import { Button } from "../button/button.comp";
import { Pagination } from "../pagination/pagination";
import { useRouter, useSearchParams } from "next/navigation";

interface IBlogPostsProps {
  blogPosts: IBlogPage[];
  category?: string;
}

const pageSize = 17;

export const BlogPosts = ({ blogPosts, category }: IBlogPostsProps) => {
  return (
    <Suspense>
      <PostsCatalog blogPosts={blogPosts} category={category} />
    </Suspense>
  );
};

function PostsCatalog({ blogPosts, category }: IBlogPostsProps) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const page = searchParams.get("page");

  const [filter, setFilter] = useState({
    name: "",
  });

  const filteredPosts = blogPosts
    .filter((post) => {
      return post.name.toLowerCase().includes(filter.name.toLowerCase());
    })
    .filter((p) => {
      const cats = (p.categories || []).map((c) =>
        c?.name?.toLowerCase?.()?.replaceAll?.(" ", "-")
      );
      if (cats.length) {
        return cats.includes((category as string) || "");
      }
      return true;
    });

  function onPaginate(page: number) {
    if (page === 1) {
      router.replace(`/blog/${category ? `category/${category}` : ""}`);
      return;
    }
    router.replace(
      `/blog/${category ? `category/${category}` : ""}?page=${page}`
    );
  }

  const startIndex = (Number(page || 1) - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedPosts = filteredPosts.slice(startIndex, endIndex);
  const totalPages =
    Math.ceil((filteredPosts.length + pageSize - 1) / pageSize) - 1;

  return (
    <>
      <StyledDiv>
        <form
          className="search-components"
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            title="Search Posts"
            placeholder="Search for a blog post..."
            value={filter.name}
            onChange={(e) => {
              setFilter({ ...filter, name: e.target.value });
              router.replace(`/blog/${category ? `category/${category}` : ""}`);
            }}
          />
          {filter.name && (
            <IoMdClose
              onClick={() => {
                setFilter({ ...filter, name: "" });
                router.replace(
                  `/blog/${category ? `category/${category}` : ""}`
                );
              }}
              className="reset"
              fontSize={20}
            />
          )}
        </form>
        <section className="posts">
          {(paginatedPosts || []).length > 0 && (
            <Link
              title={paginatedPosts[0].name}
              href={`/blog/${paginatedPosts[0].slug}`}
            >
              <div className={`post first-post`}>
                <Image
                  src={paginatedPosts[0].coverImage}
                  alt={paginatedPosts[0].name}
                  width={600}
                  height={375}
                />
                <div className="details">
                  <h3>{paginatedPosts[0].name}</h3>
                  <p className="author">
                    {paginatedPosts[0].author},
                    <time dateTime={paginatedPosts[0].publishedDate}>
                      {" "}
                      {dayjs(paginatedPosts[0].publishedDate).format(
                        "MMM DD, YYYY"
                      )}
                    </time>
                  </p>
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: paginatedPosts[0].excerpt,
                    }}
                  ></div>
                  <button className="read-more">
                    Read More
                    <IoIosArrowForward className="icon" />
                  </button>
                </div>
              </div>
            </Link>
          )}
          <div className="posts-container">
            {(blogPosts || []).length > 0 &&
              (paginatedPosts || []).length > 0 &&
              paginatedPosts.slice(1).map((post, idx) => (
                <Link
                  title={post.name}
                  key={post.name}
                  href={`/blog/${post.slug}`}
                >
                  <div className={`post`}>
                    <Image
                      src={post.coverImage}
                      alt={post.name}
                      width={700}
                      height={350}
                    />
                    <div className="details">
                      <h3>{post.name}</h3>
                      <p className="author">
                        {post.author},
                        <time dateTime={post.publishedDate}>
                          {" "}
                          {dayjs(post.publishedDate).format("MMM DD, YYYY")}
                        </time>
                      </p>
                      <div
                        className="desc"
                        dangerouslySetInnerHTML={{ __html: post.excerpt }}
                      ></div>
                      <button className="read-more">
                        Read More
                        <IoIosArrowForward className="icon" />
                      </button>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
          {!filteredPosts.length && (
            <div className="no-items">
              No posts found matching your search criteria.
              <Button
                onClick={(e) => {
                  setFilter({ ...filter, name: "" });
                  router.replace(
                    `/blog/${category ? `category/${category}` : ""}`
                  );
                }}
                variant="secondary"
              >
                Clear Search
              </Button>
            </div>
          )}
        </section>
      </StyledDiv>
      <Pagination
        currentPage={Number(page) || 1}
        onPageChange={onPaginate}
        totalCount={totalPages}
        pageSize={1}
      />
      <NewsLetterForm />
    </>
  );
}
