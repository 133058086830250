import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledBlogHero = styled.section`
	text-align: center;
	overflow: hidden;
	background: url(/assets/hero-bg.webp);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center 0%;
	color: ${CSSVars.white};

	.hero-wrapper {
		padding: 80px 20px 20px;

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			padding: 130px 40px 20px;
		}
		
		position: relative;

		span {
			font-size: ${CSSVars.fontSizeXL};
			margin-bottom: 20px;
			display: block;
			color: ${CSSVars.white};
		}

		h1 {
			position: relative;
			z-index: 10;
			margin: 0 auto;
			font-size: ${CSSVars.fontSize2XL};
			max-width: 750px;
			margin-bottom: 20px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize3XL};
			}
		}

		h2 {
			position: relative;
			z-index: 10;
			margin: 0 auto 50px;
			font-size: ${CSSVars.fontSizeLG};
			max-width: 670px;
			font-weight: 300;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSizeXL};
			}
		}
	}
`;
